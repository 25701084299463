import React from "react"
import { Link } from "gatsby"
import Container from "layout/Container"
import Collapsible from "elements/Collapsible"
import SEO from "layout/SEO"

const TermsAndConditions = () => {
  return (
    <Container isCentered>
      <SEO title="Terms and Conditions" />
      <h2>Terms and Conditions</h2>
      <hr />
      <div className="content">
        <h3>Content</h3>
        <p>
          The content of the Website, information, text, graphics, images,
          logos, icons, design, and the collection, arrangement, and assembly of
          content on the Website, are the property of MedGrocer. The User shall
          not modify the MedGrocer Content or reproduce, display, publicly
          perform, distribute, or otherwise use the MedGrocer content in any way
          for any public or commercial purpose or for personal gain.
        </p>
        <p>
          MedGrocer Content may communicate basic information about medications,
          supplements, pharmaceuticals, and other personal or health care
          products that may not be complete, up-to-date, or free from errors or
          omissions. MedGrocer may make changes or improvements at any time.
        </p>
        <p>
          The content of the Website, including but not limited to text, copy,
          audio, video, photographs, illustrations, graphics, and other visuals,
          is for informational purposes only and is not intended to be used as a
          substitute for professional medical advice, diagnosis, treatment, or
          recommendations. Individual medical concerns should be consulted with
          qualified health care professionals before taking any form of
          treatment, medication, or supplement. Reliance on any information
          appearing on the Website is solely at the User’s own risk.
        </p>

        <h3>Disclaimer</h3>
        <p>
          MedGrocer Content is provided “as is” and without warranties of any
          kind either expressed or implied, to the fullest extent permissible
          pursuant to applicable law. MedGrocer does not warrant or make any
          representations regarding the use of or the result of the use of the
          content of the Website in terms of their correctness, accuracy,
          reliability, or otherwise. MedGrocer also disclaims all liability
          resulting in loss, damage, personal injury, death, fraud, or other
          consequences arising from the use of the Website or services offered
          by MedGrocer.
        </p>

        <h3>Limitation of Liability</h3>
        <Collapsible title="General" isOpen>
          <ol>
            <li>
              MedGrocer shall not be responsible or liable in any manner to the
              User for any losses, damage, injuries, or expenses incurred by the
              User as a result of any actions or decisions taken as a result of
              using the Website or services offered by MedGrocer.
            </li>
            <li>
              In no event shall MedGrocer, or any of its directors, officers,
              employees, agents, or content or service providers be liable for
              any direct, indirect, special, incidental, consequential,
              exemplary, or punitive damages arising from, or directly or
              indirectly related to the use of or the inability to use the
              Website or the content, materials, and functions relations
              thereto.
            </li>
          </ol>
        </Collapsible>

        <Collapsible title="Services on this Website">
          <p>
            MedGrocer is not liable for any damages related to the use of our
            services, and products used through our services.
          </p>
        </Collapsible>

        <Collapsible title="Third-party Websites">
          <p>
            The Website may be linked to third-party websites, affiliates, and
            business partners. MedGrocer has no control over and accepts no
            responsibility for the content of any linked site. These linked
            sites are provided “as is” for the User’s convenience with no
            warranty. Inclusion of any link does not imply MedGrocer’s
            endorsement of the linked site.
          </p>
        </Collapsible>

        <h3>Governing Law</h3>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of the Philippines. Disputes arising from this shall be
          resolved within the justice system of the Philippines.
        </p>

        <h3>Changes</h3>
        <p>
          MedGrocer reserves the right to replace or modify the contents of
          these Terms and Conditions at any time. Continued use of our
          website/services after revisions have been made binds you to these
          revisions.
        </p>

        <h3>Contact</h3>
        <p>
          If you have any questions or issues concerning MedGrocer, the Website,
          these Terms and Conditions, or anything related, you can reach
          MedGrocer at{" "}
          <a href="mailto:admin@medgrocer.com">admin@medgrocer.com</a>.
        </p>
      </div>
    </Container>
  )
}

export default TermsAndConditions
